import { AlertError, Button, Modal, TextArea } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { useFirebase } from '../../../../../context';
import { CasesSupportAuthorizationApiRepository } from '../../../../../api';

interface Props {
  caseId: string;
  open: boolean;
  onClose: () => void;
}

export const SupportAuthorizationRejectedModal = ({
  caseId,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const endOfSupportApiRepository = useMemo(
    () => new CasesSupportAuthorizationApiRepository(auth),
    [auth],
  );

  const [notes, setNotes] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setNotes('');
  }, [caseId]);

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
  }, [caseId, open]);

  const reject = async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      await endOfSupportApiRepository.authorizationRejected(caseId, notes);
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Rejected</h3>
      <p>
        You are going to mark the case extension as <b>rejected</b> by VCS for
        <br />
        <b>{caseId}</b>
      </p>
      <TextArea
        label="Notes (optional)"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        rows={3}
      />
      <br />
      Continue?
      <br />
      <br />
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={submitting}>
          Abort
        </Button>

        <Button
          level="secondary"
          submitting={submitting}
          onClick={async () => {
            await reject();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
