import {
  UpdateEbpCaseSupportAuthorizationLevelOne,
  UpdateEbpCaseSupportAuthorizationLevelTwo,
  UpdateEbpCaseSupportAuthorizationLevelThree,
  UpdateEbpCaseSupportAuthorizationRejected,
  UpdateEbpCaseSupportAuthorizationNotRequired,
} from '@packages/core-shared';
import { ApiRepository } from '../../../helpers/ApiRepository';

export class CasesSupportAuthorizationApiRepository extends ApiRepository {
  async authorizationNotRequired(caseId: string, notes?: string) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/support/authorization/not-required`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          notes,
        } satisfies UpdateEbpCaseSupportAuthorizationNotRequired),
      },
    );

    if (!response.ok) {
      throw new Error('Error setting authorization not required');
    }
  }

  async authorizationRejected(caseId: string, notes?: string) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/support/authorization/rejected`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          notes,
        } satisfies UpdateEbpCaseSupportAuthorizationRejected),
      },
    );

    if (!response.ok) {
      throw new Error('Error setting authorization as rejected');
    }
  }

  async authorizationLevelOne(caseId: string, endDate: Date) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/support/authorization/level1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          endDate,
        } satisfies UpdateEbpCaseSupportAuthorizationLevelOne),
      },
    );

    if (!response.ok) {
      throw new Error('Error setting authorization level 3');
    }
  }

  async authorizationLevelTwo(
    caseId: string,
    endDate: Date,
    maxAppointments: number,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/support/authorization/level2`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          endDate,
          maxAppointments,
        } satisfies UpdateEbpCaseSupportAuthorizationLevelTwo),
      },
    );

    if (!response.ok) {
      throw new Error('Error setting authorization level 2');
    }
  }

  async authorizationLevelThree(caseId: string, maxAppointments: number) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/support/authorization/level3`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          maxAppointments,
        } satisfies UpdateEbpCaseSupportAuthorizationLevelThree),
      },
    );

    if (!response.ok) {
      throw new Error('Error setting authorization level 1');
    }
  }
}
