import { useMemo } from 'react';

export const useSupportEndDate = (
  selectedMonths: number,
  endDate?: Date | null,
) => {
  const newEndDate = useMemo(() => {
    if (!endDate) {
      return null;
    }

    const d = new Date(endDate);
    d.setMonth(d.getMonth() + selectedMonths);

    return d;
  }, [endDate, selectedMonths]);

  return newEndDate;
};
