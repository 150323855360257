import { CareDocumentOutcome, EbpCase } from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { useLoadMycaseTypeformsHelpers } from './useLoadMycaseTypeformsHelpers';

export const useGetMostImportantAspectOfSupport = (ebpCase: EbpCase | null) => {
  const [mostImportantAspectOfSupport, setMostImportantAspectOfSupport] =
    useState<CareDocumentOutcome | undefined>(undefined);

  const { mycawFollowupTypeformHelper } =
    useLoadMycaseTypeformsHelpers(ebpCase);

  useEffect(() => {
    const mostImportantSupportAspectValue =
      mycawFollowupTypeformHelper.getMostImportantSupportAspect();
    setMostImportantAspectOfSupport(
      mostImportantSupportAspectValue
        ? {
            initial: 'N/A baseline',
            followUp: mostImportantSupportAspectValue,
            change: 'N/a',
          }
        : undefined,
    );
  }, [mycawFollowupTypeformHelper]);

  return mostImportantAspectOfSupport;
};
