import { CareDocumentOutcome, EbpCase } from '@packages/core-shared';
import { useEffect, useMemo, useState } from 'react';
import { changeToIntString } from './changeToIntString';
import { useLoadMycaseTypeformsHelpers } from './useLoadMycaseTypeformsHelpers';

type MycawOutcome = CareDocumentOutcome & { concern?: string };

export const useGetMyCawConcerns = (ebpCase: EbpCase | null) => {
  const [mycawConcern1, setMycawConcern1] = useState<MycawOutcome>({});
  const [mycawConcern2, setMycawConcern2] = useState<MycawOutcome>({});

  const { mycawInitialTypeformHelper, mycawFollowupTypeformHelper } =
    useLoadMycaseTypeformsHelpers(ebpCase);

  useEffect(() => {
    const initialConcern1 = mycawInitialTypeformHelper.getInitialConcern1();
    const followUpConcern1Level =
      mycawFollowupTypeformHelper.getConcern1Level();

    const concern1Change =
      initialConcern1?.level !== null && followUpConcern1Level !== null
        ? parseInt(followUpConcern1Level) - parseInt(initialConcern1?.level)
        : undefined;

    setMycawConcern1({
      concern: initialConcern1.concern ?? undefined,
      initial: initialConcern1?.level ?? undefined,
      followUp: followUpConcern1Level ?? undefined,
      change: changeToIntString(concern1Change),
    });

    const initialConcern2 = mycawInitialTypeformHelper.getInitialConcern2();
    const followUpConcern2Level =
      mycawFollowupTypeformHelper.getConcern2Level();

    const concern2Change =
      initialConcern2?.level !== null && followUpConcern2Level !== null
        ? parseInt(followUpConcern2Level) - parseInt(initialConcern2?.level)
        : undefined;

    setMycawConcern2({
      concern: initialConcern2.concern ?? undefined,
      initial: initialConcern2?.level ?? undefined,
      followUp: followUpConcern2Level ?? undefined,
      change: changeToIntString(concern2Change),
    });
  }, [mycawInitialTypeformHelper, mycawFollowupTypeformHelper]);

  const result = useMemo(
    () => ({
      mycawConcern1,
      mycawConcern2,
    }),
    [mycawConcern1, mycawConcern2],
  );

  return result;
};
