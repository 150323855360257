import {} from '@packages/core-shared';
import { useMemo } from 'react';

export const useMaxAppointments = (
  selectedSessions: number,
  maxAppointments?: number,
) => {
  const result = useMemo(() => {
    let oldMaxAppointments = maxAppointments ?? 0;

    const newMaxAppointments = oldMaxAppointments + selectedSessions;

    return { oldMaxAppointments, newMaxAppointments };
  }, [maxAppointments, selectedSessions]);

  return result;
};
