import { CareDocumentOutcome, EbpCase } from '@packages/core-shared';
import { changeToIntString } from './changeToIntString';
import { useEffect, useState } from 'react';
import { useLoadMycaseTypeformsHelpers } from './useLoadMycaseTypeformsHelpers';

export const useGetTypeformOverallWellbeingChange = (
  ebpCase: EbpCase | null,
) => {
  const [overallWellbeing, setOverallWellbeing] = useState<CareDocumentOutcome>(
    {},
  );

  const { mycawInitialTypeformHelper, mycawFollowupTypeformHelper } =
    useLoadMycaseTypeformsHelpers(ebpCase);

  useEffect(() => {
    const initialOverallWellbeingLevel =
      mycawInitialTypeformHelper.getFeelingLevel() ?? undefined;
    const followUpWellbeingLevel =
      mycawFollowupTypeformHelper.getFeelingLevel() ?? undefined;

    const wellbeingChange =
      initialOverallWellbeingLevel && followUpWellbeingLevel
        ? parseInt(followUpWellbeingLevel) -
          parseInt(initialOverallWellbeingLevel)
        : undefined;
    setOverallWellbeing({
      initial: initialOverallWellbeingLevel,
      followUp: followUpWellbeingLevel,
      change: changeToIntString(wellbeingChange),
    });
  }, [mycawInitialTypeformHelper, mycawFollowupTypeformHelper]);

  return overallWellbeing;
};
