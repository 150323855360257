import { EbpCaseProgress, OrganizationId } from '@packages/core-shared';
import styles from './CaseProgress.module.css';
import { classList, Tooltip } from '@percihealth/react';
import { PopupProps } from 'reactjs-popup/dist/types';

export default function CaseProgress({
  size,
  progress,
  organizationId,
}: {
  size: 'small' | 'medium';
  progress: EbpCaseProgress;
  organizationId: string;
}) {
  const tooltipProps: Pick<PopupProps, 'position'> = {
    position: 'bottom left',
  };

  return (
    <div className={classList(styles.container, styles[size])}>
      <Tooltip
        {...tooltipProps}
        trigger={<div className={styles[progress.stage1]} />}
      >
        <div>
          <b>Stage 1 - Referral</b>
          <br />
          <b>Green</b> - booked or completed
          <br />
          <b>Amber</b> - sent, paused, appointment cancelled
          <br />
          <b>Red</b> - cancelled
          <br />
          <b>Grey</b> - no referral
        </div>
      </Tooltip>

      <Tooltip
        {...tooltipProps}
        trigger={<div className={styles[progress.stage2]} />}
      >
        <div>
          <b>Stage 2 - First apppointment</b>
          <br />
          <b>Green</b> - attended
          <br />
          <b>Amber</b> - booked or rescheduled
          <br />
          <b>Red</b> - cancelled, not attended
          <br />
          <b>Grey</b> - no appointment
        </div>
      </Tooltip>

      <Tooltip
        {...tooltipProps}
        trigger={<div className={styles[progress.stage3]} />}
      >
        <div>
          <b>Stage 3 - Care plan</b>
          <br />
          <b>Green</b> - first appointment completed and at least one PCP
          uploaded to Healee
          <br />
          <b>Amber</b> - first appointment completed and at least one PCP
          created
          <br />
          <b>Red</b> - first appointment completed and no PCP created
          <br />
          <b>Grey</b> - no appointment
        </div>
      </Tooltip>

      <Tooltip
        {...tooltipProps}
        trigger={<div className={styles[progress.stage4]} />}
      >
        <div>
          <b>Stage 4 - Activity</b>
          <br />
          <b>Green</b> - active/engaged member: booked future appointments, or
          the case is discharged with at least one appointment (L&G only)
          <br />
          <b>Amber</b> - inactive member: booked, rescheduled or completed an
          appointment in the last 3 months, but have no future booking
          <br />
          <b>Red</b> - no appointment activity in the last 3 months and no
          future booking
          <br />
          <b>Grey</b> - no appointment
        </div>
      </Tooltip>

      {organizationId === OrganizationId.landg && (
        <Tooltip
          {...tooltipProps}
          trigger={<div className={styles[progress.stage5]} />}
        >
          <div>
            <b>Stage 5 - Discharge (L&G only)</b>
            <br />
            <b>Green</b> - discharged
            <br />
            <b>Amber</b> - discharge date set and has not past
            <br />
            <b>Red</b> - discharge date set and has past
            <br />
            <b>Grey</b> - discharge date not set
          </div>
        </Tooltip>
      )}
    </div>
  );
}
