import LandgRequestSupportAuthorizationFormLink from './LandgRequestSupportAuthorizationFormLink';
import {
  dateToLondonShortDateString,
  EbpCasePatientSupportLevel,
  EbpLandgCase,
  ReferralLandg,
} from '@packages/core-shared';
import { Button } from '@percihealth/react';
import { SupportAuthorizationRejectedModal } from './SupportAuthorizationRejectedModal';
import { useEffect, useMemo, useState } from 'react';
import { SupportAuthorizationApprovedLevelOneModal } from './SupportAuthorizationApprovedLevelOneModal';
import { SupportAuthorizationApprovedLevelTwoModal } from './SupportAuthorizationApprovedLevelTwoModal';
import { SupportAuthorizationApprovedLevelThreeModal } from './SupportAuthorizationApprovedLevelThreeModal';
import { SupportAuthorizationActionHistory } from './SupportAuthorizationActionHistory';
import { SupportAuthorizationNotRequiredModal } from './SupportAuthorizationNotRequiredModal';
import styles from './SupportAuthorization.module.css';

export const SupportAuthorization = ({
  ebpCase,
  landgReferral,
}: {
  ebpCase: EbpLandgCase;
  landgReferral: ReferralLandg | null;
}) => {
  const [showApprovedLevel1, setShowApprovedLevel1] = useState(false);
  const [showApprovedLevel2, setShowApprovedLevel2] = useState(false);
  const [showApprovedLevel3, setShowApprovedLevel3] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showNotRequiredModal, setShowNotRequiredModal] = useState(false);

  useEffect(() => {
    setShowApprovedLevel1(false);
    setShowApprovedLevel2(false);
    setShowApprovedLevel3(false);
    setShowRejectedModal(false);
    setShowNotRequiredModal(false);
  }, [ebpCase.id]);

  const leftMonths = useMemo(() => {
    if (!ebpCase.support?.endDate) {
      return null;
    }

    const now = new Date();

    const endDate = ebpCase.support.endDate;
    if (endDate < now) {
      return 0;
    }

    const diffTime = endDate.getTime() - now.getTime();
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  }, [ebpCase.support?.endDate]);

  return (
    <div className="full-width">
      <div className={styles.top}>
        <div>
          <div>
            <b>{ebpCase.patient.level ?? 'Level: -'}</b>
          </div>
          {(ebpCase.patient.level === EbpCasePatientSupportLevel.One ||
            ebpCase.patient.level === EbpCasePatientSupportLevel.Two) && (
            <>
              <div>
                End of support:{' '}
                {ebpCase.support?.endDate
                  ? dateToLondonShortDateString(ebpCase.support.endDate)
                  : '-'}
              </div>
              <div>Months left: {leftMonths}</div>
            </>
          )}

          {ebpCase.patient.level === EbpCasePatientSupportLevel.Two && (
            <div>
              Professional sessions:{' '}
              {ebpCase.appointments?.completedByProfessionals ?? 0} out of{' '}
              {ebpCase.support?.maxAppointments ?? '-'} completed
            </div>
          )}
          {ebpCase.patient.level === EbpCasePatientSupportLevel.Three && (
            <div>
              Sessions: {ebpCase.appointments?.completed ?? 0} out of{' '}
              {ebpCase.support?.maxAppointments ?? '-'} completed
            </div>
          )}
        </div>
        <div className={styles.links}>
          <LandgRequestSupportAuthorizationFormLink
            patientFullname={`${ebpCase.patient?.firstName} ${ebpCase.patient?.lastName}`}
            careLevel={ebpCase.patient.level}
            gipCode={landgReferral?.gip?.code}
            // L&G does not have a referrer, using case manager
            // https://perci-health.monday.com/boards/6276536070/pulses/7095615024
            referrerEmail={landgReferral?.caseManager?.email}
          />
        </div>
      </div>

      <div className={styles.response}>
        <div>Authorisation Admin - VCS Response outcome or not required</div>
        <div className={styles['response-actions']}>
          <Button
            onClick={() => {
              switch (ebpCase.patient.level) {
                case EbpCasePatientSupportLevel.One:
                  setShowApprovedLevel1(true);
                  break;
                case EbpCasePatientSupportLevel.Two:
                  setShowApprovedLevel2(true);
                  break;
                case EbpCasePatientSupportLevel.Three:
                  setShowApprovedLevel3(true);
                  break;
              }
            }}
          >
            Approved
          </Button>
          <Button onClick={() => setShowRejectedModal(true)}>Rejected</Button>
          <Button onClick={() => setShowNotRequiredModal(true)}>
            Not required
          </Button>
        </div>
        <SupportAuthorizationApprovedLevelOneModal
          caseId={ebpCase.id}
          endDate={ebpCase.support?.endDate}
          open={showApprovedLevel1}
          onClose={() => {
            setShowApprovedLevel1(false);
          }}
        />
        <SupportAuthorizationApprovedLevelTwoModal
          caseId={ebpCase.id}
          endDate={ebpCase.support?.endDate}
          maxAppointments={ebpCase.support?.maxAppointments}
          open={showApprovedLevel2}
          onClose={() => {
            setShowApprovedLevel2(false);
          }}
        />
        <SupportAuthorizationApprovedLevelThreeModal
          caseId={ebpCase.id}
          maxAppointments={ebpCase.support?.maxAppointments}
          open={showApprovedLevel3}
          onClose={() => {
            setShowApprovedLevel3(false);
          }}
        />
        <SupportAuthorizationRejectedModal
          caseId={ebpCase.id}
          open={showRejectedModal}
          onClose={() => {
            setShowRejectedModal(false);
          }}
        />
        <SupportAuthorizationNotRequiredModal
          caseId={ebpCase.id}
          open={showNotRequiredModal}
          onClose={() => {
            setShowNotRequiredModal(false);
          }}
        />
      </div>
      <br />
      <br />
      <SupportAuthorizationActionHistory caseId={ebpCase.id} />
    </div>
  );
};
