import { useMemo } from 'react';
import { useLoadPatientAppointments } from './useLoadPatientAppointments';
import { Appointment } from '@packages/core-shared';

/***
 * Loads appointments by patient email.
 * Doctor might provide multiple services, so Healee attaches multiple services to the appoitment.
 * It results in multiple doctor records in PDFs.
 * If the appointment service is among recommnded, then the rest services will be removed.
 * Returns Map<serviceId, appointment with modified services>
 */
export const useLoadPatientAppointmentsByService = ({
  patientEmail,
  recommendedServices,
}: {
  patientEmail?: string;
  recommendedServices: number[];
}) => {
  const appointmentsLoader = useLoadPatientAppointments({
    patientEmail,
  });

  const records = useMemo(() => {
    const apps = appointmentsLoader.records
      // filter services that were not recommended
      .map((app) => {
        const recommendedService = app.services?.find(
          (s) => !!s && recommendedServices.includes(s),
        );
        if (recommendedService) {
          return {
            ...app,
            services: app.services?.filter((s) => s === recommendedService),
          };
        } else {
          return app;
        }
      });

    // appointments are ordered by date DESC
    const result = apps.reduce(
      (acc, app) => {
        // take the ealiest one
        for (const serviceId of app.services ?? []) {
          if (serviceId) {
            if (!acc[serviceId.toString()]) {
              acc[serviceId.toString()] = [];
            }
            acc[serviceId.toString()].push(app);
          }
        }
        return acc;
      },
      {} as Record<string, Appointment[]>,
    );

    return result;
  }, [appointmentsLoader.records, recommendedServices]);

  const result = useMemo(
    () => ({
      records,
      loading: appointmentsLoader.loading,
      errorMessage: appointmentsLoader.errorMessage,
    }),
    [records, appointmentsLoader.loading, appointmentsLoader.errorMessage],
  );

  return result;
};
