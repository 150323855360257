import { useMemo } from 'react';
import { useFirebase } from '../../../context';
import { collection, orderBy, query } from 'firebase/firestore';
import { useSubscribeFirestoreList } from '../../../hooks/useSubscribeFirestoreList';
import { EbpCaseSupportAuthorizationAction } from '@packages/core-shared';

export const useSubscribeSupportAuthorizationHistory = (caseId: string) => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    const q = query(
      collection(firestore, 'cases', caseId, 'supportAuthorization'),
      orderBy('createdAt', 'desc'),
    );
    return q;
  }, [firestore, caseId]);

  const subscriber = useSubscribeFirestoreList<
    any,
    EbpCaseSupportAuthorizationAction
  >(queryResult);
  return subscriber;
};
