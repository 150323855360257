import { Button, Datepicker, Tooltip } from '@percihealth/react';
import { DischargeConfirmationModal } from '../../Discharge/DischargeConfirmationModal';
import {
  CareDocument,
  CareDocumentType,
  earliestDayTime,
  EbpCase,
  LandgCareDocument,
  OrganizationId,
} from '@packages/core-shared';
import { useEffect, useMemo, useState } from 'react';
import styles from './CreatedCaseStatusBarComponent.module.css';
import { EstimatedDischargeDateConfirmationModal } from './EstimatedDischargeDateConfirmationModal';

export const CreatedCaseStatusBarComponent = ({
  ebpCase,
  documentsLoader,
}: {
  ebpCase: EbpCase;
  documentsLoader: {
    loading: boolean;
    errorMessage: string | null;
    records: CareDocument[];
  };
}) => {
  const [newEstimatedDischargeDate, setNewEstimatedDischargeDate] =
    useState<Date | null>(null);
  const [showEstimatedDischargeModal, setShowEstimatedDischargeModal] =
    useState(false);
  const [showDischargeModal, setShowDischargeModal] = useState(false);

  useEffect(() => {
    setShowDischargeModal(false);
    setShowEstimatedDischargeModal(false);
  }, [ebpCase?.id]);

  const canDischarge = useMemo(() => {
    return (
      ebpCase?.organization.id === OrganizationId.landg &&
      documentsLoader.records.some(
        (doc) =>
          doc.type === CareDocumentType.EndOfCareSummary &&
          (doc as LandgCareDocument).sentToCaseManager,
      )
    );
  }, [ebpCase?.organization.id, documentsLoader.records]);

  return (
    <div style={{ marginBottom: '-24px' }}>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Datepicker
          className={styles.datepicker}
          label="Estimated discharge date"
          value={ebpCase.estimatedDischargeDate}
          readOnly={showEstimatedDischargeModal}
          onChange={(date: any) => {
            const newValue = date ? earliestDayTime(date) : null;
            if (
              ebpCase.estimatedDischargeDate === newValue ||
              (ebpCase.estimatedDischargeDate?.getFullYear() ===
                newValue?.getFullYear() &&
                ebpCase.estimatedDischargeDate?.getMonth() ===
                  newValue?.getMonth() &&
                ebpCase.estimatedDischargeDate?.getDate() ===
                  newValue?.getDate())
            ) {
              return;
            }
            setNewEstimatedDischargeDate(newValue);
            setShowEstimatedDischargeModal(true);
          }}
        />
        <EstimatedDischargeDateConfirmationModal
          caseId={ebpCase.id}
          estimatedDischargeDate={newEstimatedDischargeDate}
          open={showEstimatedDischargeModal}
          onClose={() => setShowEstimatedDischargeModal(false)}
        />
        <Tooltip
          disabled={canDischarge}
          position="left center"
          trigger={
            <div>
              <Button
                className={styles.discharge}
                disabled={!canDischarge}
                onClick={() => {
                  setShowDischargeModal(true);
                }}
              >
                Discharge
              </Button>
            </div>
          }
        >
          <div>
            available when "End of Care summary" is shared with the L&G case
            manager
          </div>
        </Tooltip>
      </div>
      <DischargeConfirmationModal
        caseId={ebpCase.id}
        patientFullname={`${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`}
        open={showDischargeModal}
        onClose={() => setShowDischargeModal(false)}
      />
    </div>
  );
};
