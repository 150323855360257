import { AlertError, Button, DropdownSearch } from '@percihealth/react';
import { useMemo, useState } from 'react';
import { CasesApiRepository } from '../../../api';

import styles from './LevelDropdown.module.css';
import { useFirebase } from '../../../context';
import { EbpCasePatientSupportLevel } from '@packages/core-shared';

const levels = [
  '-',
  EbpCasePatientSupportLevel.One,
  EbpCasePatientSupportLevel.Two,
  EbpCasePatientSupportLevel.Three,
];

export default function LevelDropdown({
  disabled,
  initialValue,
  caseId,
}: {
  disabled: boolean;
  initialValue?: string;
  caseId: string;
}) {
  const { auth } = useFirebase();

  const [value, setValue] = useState(initialValue);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  return (
    <div>
      <div className={styles['level-dropdown-container']}>
        <div className={styles['level-dropdown']}>
          <DropdownSearch
            disabled={disabled || submitting}
            multiple={false}
            value={value ?? '-'}
            options={levels.map((o) => ({
              value: o,
              name: o,
            }))}
            onChange={(v: any) => {
              setValue(v === '-' ? undefined : v);
            }}
          />
        </div>
        {!disabled && (
          <Button
            submitting={submitting}
            onClick={async () => {
              setErrorMessage(null);

              if (!value) {
                setErrorMessage('Please select the level');
                return;
              }

              try {
                setSubmitting(true);
                await casesApiRepository.updateLevel(caseId, {
                  patient: { level: value },
                });
              } catch (err) {
                console.error(err);
                setErrorMessage(
                  'Failed to save the level, ' + (err as Error).message,
                );
              } finally {
                setSubmitting(false);
              }
            }}
          >
            Save
          </Button>
        )}
      </div>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
}
