import {
  CareDocumentOutcome,
  EbpCase,
  EbpTypeformType,
  RtwSeTypeform,
  WorkFocusTypeform,
} from '@packages/core-shared';
import { useSubscribeTypeforms } from '@packages/web-shared/api';
import { useEffect, useState } from 'react';
import { changeToDecimalString } from './changeToDecimalString';

export const useGetTypeformRtwSe = (ebpCase: EbpCase | null) => {
  const [returnToWorkSelfEfficacy, setReturnToWorkSelfEfficacy] =
    useState<CareDocumentOutcome>({});

  // The same VRP PROMs form were used for both initial and follow-up scores
  // Initial - first form filled in
  // Follow-up - latest form filled in
  const { records: workFocusedTypeforms } =
    useSubscribeTypeforms<WorkFocusTypeform>({
      patientEmail: ebpCase?.patient.email ?? null,
      formType: EbpTypeformType.WorkFocused,
    });

  const { records: rtwSeTypeforms } = useSubscribeTypeforms<RtwSeTypeform>({
    patientEmail: ebpCase?.patient.email ?? null,
    formType: EbpTypeformType.RtwSe,
  });

  useEffect(() => {
    // Combine new forms with the old PROMs
    const forms = [...rtwSeTypeforms, ...workFocusedTypeforms];
    // sort in asending createdAt order
    forms.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    const firstTypeform = forms.length > 0 ? forms[0] : undefined;
    const lastTypeform = forms.length > 0 ? forms[forms.length - 1] : undefined;

    const initialReturnToWorkSelfEfficacy = firstTypeform
      ? firstTypeform.formType === EbpTypeformType.RtwSe
        ? (firstTypeform as RtwSeTypeform).var_rtw_se
        : (firstTypeform as WorkFocusTypeform).var_rtw_se
      : undefined;

    const followUpReturnToWorkSelfEfficacy =
      firstTypeform && lastTypeform && lastTypeform.id !== firstTypeform.id
        ? lastTypeform.formType === EbpTypeformType.RtwSe
          ? (lastTypeform as RtwSeTypeform).var_rtw_se
          : (lastTypeform as WorkFocusTypeform).var_rtw_se
        : undefined;

    const returnToWorkSelfEfficacyChange =
      initialReturnToWorkSelfEfficacy && followUpReturnToWorkSelfEfficacy
        ? parseFloat(followUpReturnToWorkSelfEfficacy) -
          parseFloat(initialReturnToWorkSelfEfficacy)
        : undefined;
    setReturnToWorkSelfEfficacy({
      initial: initialReturnToWorkSelfEfficacy,
      followUp: followUpReturnToWorkSelfEfficacy,
      change: changeToDecimalString(returnToWorkSelfEfficacyChange),
    });
  }, [rtwSeTypeforms, workFocusedTypeforms]);

  return returnToWorkSelfEfficacy;
};
