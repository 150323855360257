import { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import { Button, AlertError, Spinner, classList } from '@percihealth/react';
import { useFirebase } from '@packages/web-shared';
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import {
  EmailEvent,
  dateToLocaleDateString,
  normalizeDoc,
  EbpCaseEmail,
  ebpCaseEmails,
  OrganizationId,
} from '@packages/core-shared';
import { EmailsApiRepository } from '@packages/web-shared/api';
import styles from './CaseEmailList.module.css';

export default function CaseEmailList({
  caseId,
  organizationId,
}: {
  caseId: string;
  organizationId: string;
}) {
  const { firestore, auth } = useFirebase();

  const emailsApiRepository = useMemo(
    () => new EmailsApiRepository(auth),
    [auth],
  );

  const [submittingResend, setSubmittingResendResend] = useState(false);
  const [updatingPauboxStatus, setUpdatingPauboxStatus] = useState(false);
  const [responseError, setResponseError] = useState<string | undefined>(
    undefined,
  );
  const [emails, setEmails] = useState<EbpCaseEmail[]>([]);
  const [events, setEvents] = useState<
    { id: string; template: string; description: string }[]
  >([]);
  const [loadingEvents, setLoadingEvents] = useState(false);

  useEffect(() => {
    setLoadingEvents(true);

    const unsubscribe = onSnapshot(
      query(collection(firestore, 'emails'), where('caseId', '==', caseId)),
      async (snapshot) => {
        const emailRows = snapshot.docs.map((doc) => ({
          ...normalizeDoc<EbpCaseEmail>(doc)!,
          ref: doc.ref,
        }));

        let eventsResult: any[] = [];
        for (const emailRow of emailRows) {
          try {
            eventsResult = eventsResult.concat(
              (
                await getDocs(
                  collection(firestore, 'emails', emailRow.id, 'events'),
                )
              ).docs.map((emailEventDoc) => {
                const emailEvent = normalizeDoc<EmailEvent>(emailEventDoc)!;
                return {
                  id: emailEvent.id,
                  template: emailRow.template,
                  description: `${dateToLocaleDateString(
                    emailEvent.createdAt,
                  )}: status "${emailEvent.RecordType}"`,
                };
              }),
            );
          } catch (err) {
            eventsResult.push({
              id: emailRow.id,
              template: 'error',
              description: `Failed to load events for the email id '${emailRow.id}'. Please refresh the page.`,
            });
            console.error(err);
          }
        }

        setEmails(emailRows);
        eventsResult.sort((a, b) => (a?.id ?? '').localeCompare(b?.id ?? ''));
        setEvents(eventsResult);
        setLoadingEvents(false);
      },
      (error: any) => {
        setLoadingEvents(false);
        setResponseError(error.message);
      },
    );

    return () => unsubscribe();
  }, [firestore, caseId]);

  const resendEmail = async (templateId: any) => {
    setSubmittingResendResend(true);
    try {
      await emailsApiRepository.resend({
        caseId,
        template: templateId,
      });
    } catch (error) {
      console.error(error);
      setResponseError((error as Error).message);
    } finally {
      setSubmittingResendResend(false);
    }
  };

  const updatePauboxStatus = async (emailRef: any) => {
    if (!emailRef) {
      return;
    }

    setUpdatingPauboxStatus(true);
    try {
      await emailsApiRepository.syncStatus(emailRef);
    } catch (error) {
      console.log(error);
      setResponseError((error as Error).message);
    } finally {
      setUpdatingPauboxStatus(false);
    }
  };

  const data = useMemo(
    () =>
      [
        // Email with a document re-sending is handled at the "Sharing activity" section
        // organizationId === OrganizationId.landg ? ebpCaseEmails.ToLandgCaseManagerDocument : undefined,
        organizationId === OrganizationId.landg
          ? ebpCaseEmails.ToLandgCaseManagerFeedback
          : undefined,
        organizationId === OrganizationId.landg
          ? ebpCaseEmails.ToLandgClientDischarged
          : undefined,
        organizationId === OrganizationId.landg
          ? ebpCaseEmails.ToLandgClientFeedback
          : undefined,
      ]
        .filter((tmpl) => !!tmpl)
        .map((tmpl) => {
          const templateId = tmpl.id;
          const caseEmail = emails.find((e) => e.template === templateId);
          return {
            templateId: tmpl.id,
            templateName: tmpl.name,
            status: caseEmail ? caseEmail.status : 'n/a',
            emailRef: caseEmail?.id,
            history: {
              events: events.filter((e) => e.template === tmpl.id),
              emailRef: caseEmail?.id,
            },
          };
        })
        // filter old templates that have not email events
        .filter(
          (row) =>
            row.status !== 'n/a' || !row.templateName.startsWith('(Old)'),
        ),
    [emails, events],
  );

  const historyCellView = ({ row }: any) => (
    <div className={styles.history}>
      <div className={styles.historytext}>
        {row.values.history.events.length > 0 ? (
          <ul>
            {row.values.history.events.map((e: any) => (
              <li key={e.id}>{e.description}</li>
            ))}
          </ul>
        ) : (
          <span>n/a</span>
        )}
      </div>
      <div>
        {row.values.history.emailRef &&
          row.values.history.emailRef.startsWith('paubox') && (
            <>
              <Button
                className={styles['status-btn']}
                onClick={() => updatePauboxStatus(row.values.emailRef)}
                submitting={updatingPauboxStatus}
              >
                Update status and history
              </Button>
              <div className={styles['paubox-hint']}>
                Paubox message status is tracked automatically once per day for
                7 days maximum, please use manual synchronization by clicking
                the button above.
              </div>
            </>
          )}
      </div>
    </div>
  );

  const resendCellView = ({ row }: any) => (
    <Button
      className={styles.resend}
      onClick={async () => resendEmail(row.values.templateId)}
      submitting={submittingResend}
    >
      Resend
    </Button>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Template',
        accessor: 'templateName',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Events',
        accessor: 'history',
        Cell: historyCellView,
      },
      {
        Header: 'Resend',
        accessor: 'templateId',
        Cell: resendCellView,
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data } as any);

  if (loadingEvents) {
    return <Spinner />;
  }

  return (
    <div className={classList('full-width', styles.grid)}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key: headerKey, ...headerProps } =
              headerGroup.getHeaderGroupProps();

            return (
              <tr key={headerKey} {...headerProps}>
                {headerGroup.headers.map((column) => {
                  const { key: columnKey, ...columnProps } =
                    column.getHeaderProps();
                  return (
                    <th key={columnKey} {...columnProps}>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...rowProps } = row.getRowProps();
            return (
              <tr key={rowKey} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();
                  return (
                    <td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {responseError && <AlertError>{responseError}</AlertError>}
    </div>
  );
}
