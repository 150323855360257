import {
  EbpCase,
  MycawFollowUpTypeform,
  MycawFollowUpTypeformHelper,
  MycawInitialTypeform,
  MycawInitialTypeformHelper,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';

export const useLoadMycaseTypeformsHelpers = (ebpCase: EbpCase | null) => {
  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialTypeform>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpTypeform>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  const result = useMemo(() => {
    const mycawInitialTypeformHelper = new MycawInitialTypeformHelper(
      mycawInitialForm,
    );

    // Consider followup form only if it was created after the initial mycaw
    const mycawFollowUpFormResult =
      mycawInitialForm &&
      mycawFollowUpForm &&
      mycawFollowUpForm.createdAt > mycawInitialForm.createdAt
        ? mycawFollowUpForm
        : null;

    const mycawFollowupTypeformHelper = new MycawFollowUpTypeformHelper(
      mycawFollowUpFormResult,
    );

    return { mycawInitialTypeformHelper, mycawFollowupTypeformHelper };
  }, [mycawInitialForm, mycawFollowUpForm]);

  return result;
};
