import '@percihealth/react/css/index.scss';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import firebaseApp from './firebase';
import MainLayout from '@packages/web-shared/components/cases/Layout/MainLayout';
import Header from './components/Header/Header';
import CaseListPage from '@packages/web-shared/components/cases/pages/CaseListPage';
import CaseDetailsPage from '@packages/web-shared/components/cases/pages/CaseDetailsPage';
import CreatePcpPage from './components/pages/care-documents/pcp/CreatePcpPage';
import { ScrollToTop } from '@percihealth/react';
import {
  AskForRightsPage,
  AuthProvider,
  PrivateElement,
} from '@percihealth/react-auth';
import ExpertLoginPage from './components/pages/ExpertLoginPage';
import CreateWorkFocusedPlanPage from './components/pages/care-documents/workFocusedPlan/CreateWorkFocusedPlanPage';
import CreateEndOfCareSummaryPage from './components/pages/care-documents/endOfCareSummary/CreateEndOfCareSummaryPage';
import CreateInterimCareSummaryPage from './components/pages/care-documents/interimCareSummary/CreateInterimCareSummaryPage';
import CreateInitialAssessmentSummaryPage from './components/pages/care-documents/initialAssessmentSummary/CreateInitialAssessmentSummaryPage';
import MedicalHistoryValidationPage from './components/pages/MedicalHistoryValidationPage';
import { FirebaseProvider } from '@packages/web-shared';

export default function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path="/"
          element={
            <MainLayout header={<Header />}>
              <ScrollToTop />
              <Outlet />
            </MainLayout>
          }
        >
          <Route
            path="/cases/:id"
            element={
              <PrivateElement>
                <CaseDetailsPage
                  allowEditPdfs
                  allowEditExpert={false}
                  allowEditTypeforms
                  allowEditConsentShareDataToInsurer
                  allowEditPatientLevel
                  showEmails={false}
                />
              </PrivateElement>
            }
          />
          <Route
            path="/cases"
            element={
              <PrivateElement>
                <CaseListPage />
              </PrivateElement>
            }
          />
          <Route
            path="/"
            element={
              <PrivateElement>
                <CaseListPage />
              </PrivateElement>
            }
          />
          <Route
            path="/cases/:caseId/care-documents/pcp"
            element={
              <PrivateElement>
                <CreatePcpPage />
              </PrivateElement>
            }
          />
          <Route
            path="/cases/:caseId/care-documents/work-focused-plan"
            element={
              <PrivateElement>
                <CreateWorkFocusedPlanPage />
              </PrivateElement>
            }
          />
          <Route
            path="/cases/:caseId/care-documents/end-of-care-summary"
            element={
              <PrivateElement>
                <CreateEndOfCareSummaryPage />
              </PrivateElement>
            }
          />
          <Route
            path="/cases/:caseId/care-documents/interim-care-summary"
            element={
              <PrivateElement>
                <CreateInterimCareSummaryPage />
              </PrivateElement>
            }
          />
          <Route
            path="/cases/:caseId/care-documents/initial-assessment-summary"
            element={
              <PrivateElement>
                <CreateInitialAssessmentSummaryPage />
              </PrivateElement>
            }
          />
          <Route path="/login" element={<ExpertLoginPage />} />
          <Route path="/ask-for-rights" element={<AskForRightsPage />} />
        </Route>
        <Route
          element={
            <MainLayout
              header={<Header />}
              containerStyle={{
                maxHeight: '100vh',
                display: 'grid',
                gridTemplateRows: 'auto 1fr auto',
              }}
              mainStyle={{ overflow: 'hidden' }}
            >
              <Outlet />
            </MainLayout>
          }
        >
          <Route
            path="/cases/:caseId/medical-history-validation"
            element={
              <PrivateElement>
                <MedicalHistoryValidationPage />
              </PrivateElement>
            }
          />
        </Route>
      </Route>,
    ),
  );

  return (
    <AuthProvider firebaseApp={firebaseApp}>
      <FirebaseProvider firebaseApp={firebaseApp}>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </FirebaseProvider>
    </AuthProvider>
  );
}
