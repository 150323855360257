import { useFirebase } from '@packages/web-shared';
import { AlertError, Button, Modal } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { CasesApiRepository } from '../../../../../../api';
import { dateToLondonNumericDateString } from '@packages/core-shared';

interface Props {
  caseId: string;
  estimatedDischargeDate: Date | null;
  open: boolean;
  onClose: () => void;
}

export const EstimatedDischargeDateConfirmationModal = ({
  caseId,
  estimatedDischargeDate,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
  }, [caseId, open]);

  const submit = async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      await casesApiRepository.updateEstimatedDischargeDate(
        caseId,
        estimatedDischargeDate,
      );
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Changing estimated discharge date</h3>
      {!estimatedDischargeDate && (
        <p>You are going to remove the estimated discharge date.</p>
      )}
      {estimatedDischargeDate && (
        <p>
          You are going to set the estimated discharge date to{' '}
          {<b>{dateToLondonNumericDateString(estimatedDischargeDate)}</b>}.
        </p>
      )}
      Continue?
      <br />
      <br />
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={submitting}>
          Abort
        </Button>

        <Button
          level="secondary"
          submitting={submitting}
          onClick={async () => {
            await submit();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
