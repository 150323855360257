import { CareDocumentOutcome, EbpCase } from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { useLoadMycaseTypeformsHelpers } from './useLoadMycaseTypeformsHelpers';

export const useGetOtherFactors = (ebpCase: EbpCase | null) => {
  const [otherFactors, setOtherFactors] = useState<
    CareDocumentOutcome | undefined
  >(undefined);

  const { mycawFollowupTypeformHelper } =
    useLoadMycaseTypeformsHelpers(ebpCase);

  useEffect(() => {
    const otherFactorsValue = mycawFollowupTypeformHelper.getOtherFactors();
    setOtherFactors(
      otherFactorsValue
        ? {
            initial: 'N/A baseline',
            followUp: otherFactorsValue,
            change: 'N/a',
          }
        : undefined,
    );
  }, [mycawFollowupTypeformHelper]);

  return otherFactors;
};
