import { dateToLondonNumericDateString } from '@packages/core-shared';
import { AlertError, Button, Modal, RadioButton } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { useFirebase } from '../../../../../context';
import { CasesSupportAuthorizationApiRepository } from '../../../../../api';
import { useSupportEndDate } from './useSupportEndDate';

interface Props {
  caseId: string;
  endDate?: Date | null;
  open: boolean;
  onClose: () => void;
}

export const SupportAuthorizationApprovedLevelOneModal = ({
  caseId,
  endDate,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const endOfSupportApiRepository = useMemo(
    () => new CasesSupportAuthorizationApiRepository(auth),
    [auth],
  );
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedMonths, setSelectedMonths] = useState(1);

  const newEndDate = useSupportEndDate(selectedMonths, endDate);

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
    setSelectedMonths(1);
  }, [caseId, open]);

  const approve = async () => {
    if (!newEndDate) {
      return;
    }

    setSubmitting(true);
    setErrorMessage(null);
    try {
      await endOfSupportApiRepository.authorizationLevelOne(caseId, newEndDate);
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Approved Level 1</h3>
      {!endDate && (
        <div className="full-width">
          ⚠️ Perci end of support date is not calculated yet
        </div>
      )}
      {endDate && (
        <div>
          <p>
            How many additional months have been approved for <b>{caseId}</b>?
          </p>
          <div>
            <RadioButton
              name="extend_level_one"
              id="extend_level_one.1"
              checked={selectedMonths === 1}
              value={1}
              text="1 month"
              onChange={() => setSelectedMonths(1)}
            />
            <RadioButton
              id="extend_level_one.2"
              name="extend_level_one"
              checked={selectedMonths === 2}
              value={2}
              text="2 months"
              onChange={() => setSelectedMonths(2)}
            />
            <RadioButton
              id="extend_level_one.3"
              name="extend_level_one"
              checked={selectedMonths === 3}
              value={3}
              text="3 months"
              onChange={() => setSelectedMonths(3)}
            />
          </div>
          <br />
          The end of date will be extended till{' '}
          {dateToLondonNumericDateString(newEndDate)}?
          <br />
          <br />
          {errorMessage && <AlertError>{errorMessage}</AlertError>}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={onClose} disabled={submitting}>
              Abort
            </Button>

            <Button
              level="secondary"
              submitting={submitting}
              onClick={async () => {
                await approve();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
