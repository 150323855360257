import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { AlertError, Spinner } from '@percihealth/react';
import PdfPageViewer from './PdfPageViewer';
import {
  EbpMedicalHistoryPatientAnalyzeResult,
  Aws,
} from '@packages/core-shared';
import { EbpMedicalHistoryField } from './EbpMedicalHistoryField';
import { MedicalHistoryLocateEvents } from './MedicalHistoryLocateEvents';
import { useEvent } from '@packages/web-shared/hooks/eventEmitter/useEvent';
import { ReferralsApiRepository } from '@packages/web-shared/api';
import { useFirebase } from '@packages/web-shared';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// Bug in react-pdf
// when options are passed it re-renders multiple times
// const options = {
//   standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
// };

export default function PdfDocumentViewer({
  referralId,
  docPath,
  containerWidth,
  medicalHistoryAnalyzeResult,
  visible,
}: {
  referralId: string;
  docPath?: string;
  containerWidth: number;
  medicalHistoryAnalyzeResult?: EbpMedicalHistoryPatientAnalyzeResult | null;
  visible: boolean;
}) {
  const { auth } = useFirebase();

  const pdfDocRef = useRef<HTMLDivElement>(null);
  const [pdfPageHeights, setPdfPageHeights] = useState<Record<number, number>>(
    {},
  );

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const referralsApiRepository = useMemo(
    () => new ReferralsApiRepository(auth),
    [auth],
  );

  useEffect(() => {
    (async () => {
      if (!docPath) {
        setErrorMessage('No PDF URL provided');
        return;
      }

      setErrorMessage(null);

      try {
        const previewUrl = await referralsApiRepository.generateDownloadUrl({
          referralId,
          filePath: docPath,
        });
        setPdfUrl(previewUrl);
      } catch (error: any) {
        console.error(error);
        setErrorMessage('Unable to generate download URL: ' + error.message);
      }
    })();
  }, [referralsApiRepository, docPath]);

  const [totalPages, setTotalPages] = useState<number>(0);
  const totalPagesArray = useMemo(
    () =>
      totalPages
        ? Array(totalPages)
            .fill(0)
            .map((_, i) => i + 1)
        : [],
    [totalPages],
  );

  const pagesWithBlocks: Record<
    number,
    (Aws.Textract.Block & { type: EbpMedicalHistoryField })[]
  > = useMemo(() => {
    const analyzeResult = medicalHistoryAnalyzeResult;
    if (!analyzeResult) {
      return {};
    }

    const pageIndexes = [
      analyzeResult.cancerType?.pageIndex ?? -1,
      analyzeResult.gpPracticeName?.pageIndex ?? -1,
      analyzeResult.patient?.dateOfBirth?.pageIndex ?? -1,
      analyzeResult.patient?.address?.pageIndex ?? -1,
    ].filter((i) => i >= 0);

    // Fill empty array for each known page
    const res = pageIndexes.reduce(
      (acc, i) => {
        acc[i + 1] = [];
        return acc;
      },
      {} as Record<
        number,
        (Aws.Textract.Block & { type: EbpMedicalHistoryField })[]
      >,
    );

    if (
      analyzeResult?.cancerType?.value &&
      analyzeResult?.cancerType?.docPath === docPath
    ) {
      res[analyzeResult.cancerType.pageIndex + 1] = [
        ...(res[analyzeResult.cancerType.pageIndex + 1] ?? []),
        {
          ...analyzeResult.cancerType.block,
          type: EbpMedicalHistoryField.CancerType,
        },
      ];
    }
    if (
      analyzeResult?.gpPracticeName?.value &&
      analyzeResult?.gpPracticeName?.docPath === docPath
    ) {
      res[analyzeResult.gpPracticeName.pageIndex + 1] = [
        ...(res[analyzeResult.gpPracticeName.pageIndex + 1] ?? []),
        {
          ...analyzeResult.gpPracticeName.block,
          type: EbpMedicalHistoryField.GeneralPractice,
        },
      ];
    }
    if (
      analyzeResult?.patient?.dateOfBirth?.value &&
      analyzeResult?.patient?.dateOfBirth?.docPath === docPath
    ) {
      res[analyzeResult.patient?.dateOfBirth.pageIndex + 1] = [
        ...(res[analyzeResult.patient.dateOfBirth.pageIndex + 1] ?? []),
        {
          ...analyzeResult.patient.dateOfBirth.block,
          type: EbpMedicalHistoryField.MemberDob,
        },
      ];
    }
    if (
      analyzeResult?.patient?.address?.value &&
      analyzeResult?.patient?.dateOfBirth?.docPath === docPath
    ) {
      res[analyzeResult.patient?.address.pageIndex + 1] = [
        ...(res[analyzeResult.patient.address.pageIndex + 1] ?? []),
        {
          ...analyzeResult.patient.address.block,
          type: EbpMedicalHistoryField.MemberAddress,
        },
      ];
    }
    return res;
  }, [medicalHistoryAnalyzeResult]);

  const locate = useCallback(
    (payload: { field: EbpMedicalHistoryField; docPath: string }) => {
      const { field } = payload;

      const page = Object.keys(pagesWithBlocks)
        .map((key) => {
          const pageBlocks = pagesWithBlocks[Number(key)]!;
          const block = pageBlocks.find((b) => b.type === field);

          return block ? { pageIndex: Number(key) - 1, block } : null;
        })
        .find((p) => !!p);

      if (
        page?.block?.Geometry?.BoundingBox?.Top &&
        pdfDocRef.current &&
        pdfPageHeights[page.pageIndex + 1]
      ) {
        let heightBefore = 0;

        for (let i = 0; i < page.pageIndex; i++) {
          heightBefore += pdfPageHeights[i + 1] ?? 0;
        }

        const scrollToTopOffsite =
          heightBefore -
          20 + // 10px offset for better UX
          page.block.Geometry.BoundingBox.Top *
            pdfPageHeights[page.pageIndex + 1];

        pdfDocRef.current.scrollTo({
          top: scrollToTopOffsite,
          behavior: 'smooth',
        });
      }
    },
    [visible, pagesWithBlocks, pdfPageHeights],
  );

  const handleLocateRequest = useCallback(
    (payload: { field: EbpMedicalHistoryField; docPath: string }) => {
      // hanlde only the current document
      if (payload.docPath !== docPath) {
        return;
      }
      // give some time to render the document if needed
      setTimeout(() => {
        locate(payload);
      }, 0);
    },
    [locate, docPath],
  );

  useEvent(
    MedicalHistoryLocateEvents.ValidationLocateBlockEvent,
    handleLocateRequest,
  );

  const handlePdfHeightEvent = useCallback(
    (payload: { pageNumber: number; height: number; docPath: string }) => {
      if (payload.docPath !== docPath || !payload.height) {
        return;
      }

      setPdfPageHeights((prev) => ({
        ...prev,
        [payload.pageNumber]: payload.height,
      }));
    },
    [docPath],
  );

  // Each page will send it's height
  useEvent(MedicalHistoryLocateEvents.PdfPageHeightEvent, handlePdfHeightEvent);

  const pdfPages = useMemo(() => {
    return totalPagesArray.map((pageNumber) => (
      <PdfPageViewer
        key={pageNumber}
        visible={visible}
        docPath={docPath ?? ''}
        pageNumber={pageNumber}
        containerWidth={containerWidth}
        blocks={pagesWithBlocks[pageNumber] ?? []}
      />
    ));
  }, [pagesWithBlocks, visible, totalPagesArray, pdfUrl]);

  if (errorMessage) {
    return <AlertError>{errorMessage}</AlertError>;
  }

  if (!pdfUrl) {
    return <Spinner />;
  }

  return (
    <div
      ref={pdfDocRef}
      style={{
        overflowY: 'scroll',
        height: '100%',
        border: '1px solid',
        display: visible ? 'block' : 'none',
      }}
    >
      <Document
        file={pdfUrl}
        onLoadSuccess={({ numPages }) => {
          setTotalPages(numPages);
        }}
        // options={{ ...options }}
        loading={<Spinner />}
      >
        {pdfPages}
      </Document>
    </div>
  );
}
