import {
  CareDocumentOutcome,
  EbpCase,
  EbpTypeformType,
  FatigueTypeform,
  FatigueTypeformHelper,
  WorkFocusedPlanTypeformHelper,
  WorkFocusTypeform,
} from '@packages/core-shared';
import { useSubscribeTypeforms } from '@packages/web-shared/api';
import { useEffect, useState } from 'react';
import { changeToDecimalString } from './changeToDecimalString';

export const useGetTypeformFatigueChanges = (ebpCase: EbpCase | null) => {
  const [fatigue, setFatigue] = useState<CareDocumentOutcome>({});
  // VRP PROMS were separated on two forms: Fatigue and RTW-SE

  // The same VRP PROMs form were used for both initial and follow-up scores
  // Initial - first form filled in
  // Follow-up - latest form filled in
  const { records: workFocusedTypeforms } =
    useSubscribeTypeforms<WorkFocusTypeform>({
      patientEmail: ebpCase?.patient.email ?? null,
      formType: EbpTypeformType.WorkFocused,
    });

  const { records: fatigueTypeforms } = useSubscribeTypeforms<FatigueTypeform>({
    patientEmail: ebpCase?.patient.email ?? null,
    formType: EbpTypeformType.Fatigue,
  });

  useEffect(() => {
    // Combine new forms with the old PROMs
    const forms = [...fatigueTypeforms, ...workFocusedTypeforms];

    // sort in asending createdAt order
    forms.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

    const firstTypeform = forms.length > 0 ? forms[0] : undefined;
    const lastTypeform = forms.length > 0 ? forms[forms.length - 1] : undefined;

    const initialFatigue = firstTypeform
      ? (firstTypeform.formType === EbpTypeformType.Fatigue
          ? new FatigueTypeformHelper(firstTypeform as FatigueTypeform)
          : new WorkFocusedPlanTypeformHelper(
              firstTypeform as WorkFocusTypeform,
            )
        ).getFatigue()
      : undefined;

    const followUpFatigue =
      firstTypeform && lastTypeform && lastTypeform.id !== firstTypeform.id
        ? (lastTypeform.formType === EbpTypeformType.Fatigue
            ? new FatigueTypeformHelper(lastTypeform as FatigueTypeform)
            : new WorkFocusedPlanTypeformHelper(
                lastTypeform as WorkFocusTypeform,
              )
          ).getFatigue()
        : undefined;

    const fatigueChange =
      initialFatigue && followUpFatigue
        ? // 0 (no fatigue), 10 (worst fatigue you can imagine)
          // PDF output will use reverse coloring (-1 is better, +1 is worse)
          parseFloat(initialFatigue) - parseFloat(followUpFatigue)
        : undefined;
    setFatigue({
      initial: initialFatigue,
      followUp: followUpFatigue,
      change: changeToDecimalString(fatigueChange),
    });
  }, [workFocusedTypeforms, fatigueTypeforms]);

  return fatigue;
};
