import {
  EbpCaseExpertUpsertNote,
  EbpCaseExpertInternalNote,
  EbpCaseExpertUpsertNoteSchema,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  InputText,
  Spinner,
  TextArea,
} from '@percihealth/react';
import { useAuth } from '@percihealth/react-auth';
import { collection, query } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { CasesApiRepository } from '../../../../../api';
import { useSubscribeFirestoreList } from '../../../../../hooks/useSubscribeFirestoreList';
import styles from './ExpertInternalNotesTable.module.scss';
import { useFirebase } from '../../../../../context';

export default function ExpertInternalNotesTable({
  caseId,
  readonly,
}: {
  caseId: string;
  readonly: boolean;
}) {
  const { user } = useAuth();
  const { firestore, auth } = useFirebase();

  const queryResult = useMemo(
    () => query(collection(firestore, 'cases', caseId, 'notes')),
    [firestore, caseId],
  );

  const notesLoader =
    useSubscribeFirestoreList<EbpCaseExpertInternalNote>(queryResult);

  const notes = useMemo(() => {
    return notesLoader.records.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime();
    });
  }, [notesLoader.records]);

  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const [editingId, setEditingId] = useState('');
  const [editNote, setEditNote] = useState('');
  const [editTimeTaken, setEditTimeTaken] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const [addNote, setAddNote] = useState('');
  const [addTimeTaken, setAddTimeTaken] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onAdd = async () => {
    setIsAdding(true);
    const note: EbpCaseExpertUpsertNote = {
      note: addNote,
      timeTakenInMinutes: addTimeTaken,
    };

    try {
      await EbpCaseExpertUpsertNoteSchema.validate(note);
    } catch (err: any) {
      setErrorMessage(err.errors.join(', '));
      setIsAdding(false);
      return;
    }

    setErrorMessage(null);

    try {
      await casesApiRepository.addExpertInternalNotes(caseId, note);
      setAddNote('');
      setAddTimeTaken(0);
    } catch (err: any) {
      setErrorMessage(err.message);
    }
    setIsAdding(false);
  };

  const onUpdate = async () => {
    setIsUpdating(true);
    const note: EbpCaseExpertUpsertNote = {
      note: editNote,
      timeTakenInMinutes: editTimeTaken,
    };

    try {
      await EbpCaseExpertUpsertNoteSchema.validate(note);
    } catch (err: any) {
      setErrorMessage(err.errors.join(', '));
      setIsUpdating(false);
      return;
    }

    setErrorMessage(null);

    try {
      await casesApiRepository.updateExpertInternalNotes(
        caseId,
        editingId,
        note,
      );
      setAddNote('');
      setAddTimeTaken(0);
      setEditingId('');
    } catch (err: any) {
      setErrorMessage(err.message);
    }
    setIsUpdating(false);
  };

  if (notesLoader.loading) {
    return <Spinner />;
  }

  return (
    <div className="full-width">
      <table className={styles.table}>
        <thead>
          <tr style={{ textAlign: 'left' }}>
            <th>Note</th>
            <th>Time taken (minutes)</th>
            <th>Date time</th>
            <th>Nurse</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {notes.map((doc) => (
            <tr key={doc.id}>
              <td>
                <TextArea
                  value={editingId === doc.id ? editNote : doc.note}
                  onChange={(e) => {
                    setEditNote(e.target.value);
                  }}
                  rows={3}
                  disabled={readonly || editingId !== doc.id}
                />
              </td>
              <td>
                <InputText
                  className={styles.minutes}
                  value={(editingId === doc.id
                    ? editTimeTaken
                    : doc.timeTakenInMinutes
                  ).toString()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const number = value.replace(/[^0-9.]/g, '');
                    setEditTimeTaken(Number(number));
                  }}
                  disabled={readonly || editingId !== doc.id}
                />
              </td>
              <td>
                <span>{dateTimeToLocalDateTimeString(doc.createdAt)}</span>
              </td>
              <td>
                <span>{doc.expertId}</span>
              </td>
              <td>
                <div className={styles.actions}>
                  {editingId === doc.id && (
                    <>
                      <Button
                        onClick={onUpdate}
                        disabled={readonly}
                        submitting={isUpdating}
                      >
                        Save
                      </Button>
                      <a
                        onClick={() => {
                          setEditingId('');
                          setErrorMessage(null);
                        }}
                      >
                        Cancel
                      </a>
                    </>
                  )}

                  {editingId !== doc.id && user?.email === doc.expertId && (
                    <a
                      onClick={() => {
                        setEditNote(doc.note);
                        setEditTimeTaken(doc.timeTakenInMinutes);
                        setEditingId(doc.id);
                      }}
                    >
                      Edit
                    </a>
                  )}
                </div>
              </td>
            </tr>
          ))}
          <tr key={-1}>
            <td>
              <TextArea
                value={addNote}
                disabled={readonly}
                onChange={(e) => {
                  setAddNote(e.target.value);
                }}
                rows={3}
              />
            </td>
            <td>
              <InputText
                className={styles.minutes}
                value={addTimeTaken.toString()}
                disabled={readonly}
                onChange={(e) => {
                  const value = e.target.value;
                  const number = value.replace(/[^0-9.]/g, '');
                  setAddTimeTaken(Number(number));
                }}
              />
            </td>
            <td></td>
            <td></td>
            <td>
              <Button onClick={onAdd} disabled={readonly} submitting={isAdding}>
                Add
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
}
